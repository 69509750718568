/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'SF UI Text Regular';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Regular'), url('../../../assets/fonts/sf-ui-text/SFUIText-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Italic'), url('../../../assets/fonts/sf-ui-text/SFUIText-RegularItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Light';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Light'), url('../../../assets/fonts/sf-ui-text/SFUIText-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Light Italic'), url('../../../assets/fonts/sf-ui-text/SFUIText-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Medium';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Medium'), url('../../../assets/fonts/sf-ui-text/SFUIText-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Medium Italic'), url('../../../assets/fonts/sf-ui-text/SFUIText-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Semibold'), url('../../../assets/fonts/sf-ui-text/SFUIText-Semibold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Semibold Italic'), url('../../../assets/fonts/sf-ui-text/SFUIText-SemiboldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Bold';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Bold'), url('../../../assets/fonts/sf-ui-text/SFUIText-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Bold Italic'), url('../../../assets/fonts/sf-ui-text/SFUIText-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Heavy'), url('../../../assets/fonts/sf-ui-text/SFUIText-Heavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'SF UI Text Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Text Heavy Italic'), url('../../../assets/fonts/sf-ui-text/SFUIText-HeavyItalic.woff') format('woff');
    }















    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Medium.eot');
        src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Medium.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Medium.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Heavy.eot');
        src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Heavy.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Heavy.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Heavy.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-UltralightItalic.eot');
        src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-UltralightItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-UltralightItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-UltralightItalic.ttf') format('truetype');
        font-weight: 200;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-RegularItalic.eot');
        src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-RegularItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-RegularItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-RegularItalic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Thin.eot');
        src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Thin.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Thin.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Light.eot');
        src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Light.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Light.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Light.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-BlackItalic.eot');
        src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BlackItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BlackItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BlackItalic.ttf') format('truetype');
        font-weight: 900;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Bold.eot');
        src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Bold.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Bold.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Black.eot');
        src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Black.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Black.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-SemiboldItalic.eot');
        src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-SemiboldItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-SemiboldItalic.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Ultralight.eot');
        src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Ultralight.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Ultralight.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Ultralight.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-LightItalic.eot');
        src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-LightItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-LightItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-LightItalic.ttf') format('truetype');
        font-weight: 200;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-ThinItalic.eot');
        src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-ThinItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-ThinItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-MediumItalic.eot');
        src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-MediumItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-MediumItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-MediumItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Semibold.eot');
        src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Semibold.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Semibold.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Semibold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-HeavyItalic.eot');
        src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-HeavyItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-HeavyItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-HeavyItalic.ttf') format('truetype');
        font-weight: 900;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-Regular.eot');
        src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Regular.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Regular.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SF Pro Display';
        src: url('../../../assets/fonts/sf-pro/SFProDisplay-BoldItalic.eot');
        src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BoldItalic.woff2') format('woff2'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BoldItalic.woff') format('woff'),
            url('../../../assets/fonts/sf-pro/SFProDisplay-BoldItalic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
    }
    
    




 @font-face {
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Regular'), url('/assets/fonts/poppins/Poppins-Regular.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Italic'), url('/assets/fonts/poppins/Poppins-Italic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Thin'), url('/assets/fonts/poppins/Poppins-Thin.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Thin Italic'), url('/assets/fonts/poppins/Poppins-ThinItalic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraLight'), url('/assets/fonts/poppins/Poppins-ExtraLight.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraLight Italic'), url('/assets/fonts/poppins/Poppins-ExtraLightItalic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Light';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Light'), url('/assets/fonts/poppins/Poppins-Light.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Light Italic'), url('/assets/fonts/poppins/Poppins-LightItalic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Medium'), url('/assets/fonts/poppins/Poppins-Medium.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Medium Italic'), url('/assets/fonts/poppins/Poppins-MediumItalic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins SemiBold'), url('/assets/fonts/poppins/Poppins-SemiBold.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins SemiBold Italic'), url('/assets/fonts/poppins/Poppins-SemiBoldItalic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Bold'), url('/assets/fonts/poppins/Poppins-Bold.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Bold Italic'), url('/assets/fonts/poppins/Poppins-BoldItalic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraBold'), url('/assets/fonts/poppins/Poppins-ExtraBold.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraBold Italic'), url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Black';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Black'), url('/assets/fonts/poppins/Poppins-Black.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Poppins Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Black Italic'), url('/assets/fonts/poppins/Poppins-BlackItalic.woff') format('woff');
    }
    
    
    