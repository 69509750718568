@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';
@import 'fonts';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  .GlossClear {
    background: #f0fafc;
  }

  .RoyalBlue {
    background: #005DA2;
  }

  .Black {
    background: #040500;
  }

  .FluorescentGreen {
    background: #84CA74;
  }

  .FluorescentRed {
    background: #EC4C5E;
  }

  .Silver {
    background: #AAAEAD;
  }

  .LightBlue {
    background: #018DCB;
  }

  .BabyPink {
    background: #F583A4;
  }

  .Lilac {
    background: #5A3E95;
  }

  .Red {
    background: #D90134;
  }

  .Yellow {
    background: #FDDF3F;
  }

  .Orange {
    background: #F37B25;
  }

  .FluorescentOrange {
    background: #F38355;
  }

  .Maroon {
    background: #610036;
  }

  .Gold {
    background: #A7915F;
  }

  .Sky {
    background: #65D2F1;
  }

  .Lavender {
    background: #CAAFCE;
  }

  .Brown {
    background: #461103;
  }

  .White {
    background: #FFFFFF;
  }

  .Green {
    background: #18964D;
  }

  .FluorescentPink {
    background: #F15996;
  }

  .FluorescentYellow {
    background: #F8EC5A;
  }

  .Purple {
    background: #4A0F53;
  }

  .Navy {
    background: #102361;
  }

  .RacingGreen {
    background: #10503F;
  }

  .Turquoise {
    background: #7CCDD0;
  }

  .Apple {
    background: #99CD8D;
  }


  .note-textarea {
    background: #F8F6A91A !important;
    border: 1px solid #F6E6B9 !important;
    border-radius: 10px !important;
    padding: 12px 13px !important;
    font-family: "SF UI Text Medium";
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.01em;
  }


  * {
    letter-spacing: .3px;
  }

  .secondary-color {
    color: #222b45;
    width: 17px;
    height: 17px;
  }

  .pace .pace-progress {
    background-color: #FFFFFF;
  }

  .btn-shadow {
    box-shadow: 0px 2px 5px 0px #3C425714, 0px 1px 1px 0px #0000001F;

    &.outline {
      border: unset !important;
      box-shadow: unset !important;
    }
  }

  button .custom-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: 0 !important;
    transform: rotate(180deg) !important;
    transition: all .3s ease-in-out;
  }

  .limit-analog-options {
    max-width:100px !important;
    .ng-select .ng-select-container {
        max-width:100px !important;
        min-width: 70px !important;
    }
}

  .ng-select.button-ng-select .ng-select-container {
    width : 50px;
    min-width: 50px;
    border: 1px solid #D5DBE1;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D5DBE1, #D5DBE1);
    box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.0784313725), 0px 1px 1px 0px rgba(0, 0, 0, 0.1215686275);
    border-radius: 4px;

    &::before {
      content: '';
      background: url('../../../assets/images/icons/view-column.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 16px;
      height: 30px;
      position: absolute;
      left: 0;
      transform: rotate(90deg);
    }

    .ng-value-container,
    .ng-clear-wrapper {
      display: none;
    }

    .ng-arrow-wrapper {
      position: absolute;
      right: 0;

      .ng-arrow {
        background: url('../../../assets/images/icons/arrow-down-white.svg');
        background-repeat: no-repeat;
        width: 10px;
        height: 6px;
        background-size: contain;
      }
    }

  }
}

.ng-select.button-ng-select .ng-select-container {
  width: 50px;
  min-width: 50px;
  border: 1px solid #D5DBE1;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D5DBE1, #D5DBE1);
  box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.0784313725), 0px 1px 1px 0px rgba(0, 0, 0, 0.1215686275);
  border-radius: 4px;

  &::before {
    content: '';
    background: url('../../../assets/images/icons/view-column.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 30px;
    position: absolute;
    left: 0;
    transform: rotate(90deg);
  }

  .ng-value-container,
  .ng-clear-wrapper {
    display: none;
  }

  .ng-arrow-wrapper {
    position: absolute;
    right: 0;

    .ng-arrow {
      background: url('../../../assets/images/icons/arrow-down-white.svg');
      background-repeat: no-repeat;
      width: 10px;
      height: 6px;
      background-size: contain;
    }
  }

}

.ng-select.auto-h .ng-select-container {
  height: unset !important;
  min-height: 35px !important;
}

.ng-select {
  .ng-select-container {
    font-family: "SF UI Text Regular";
    height: 30px !important;
    line-height: 20px !important;
    font-size: 12px !important;
    letter-spacing: 0.15px !important;
    color: #414552 !important;
    padding: 4px 0px 4px 10px !important;
    min-height: 30px !important;
    border: 1px solid #D5DBE1;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D5DBE1, #D5DBE1);
    box-shadow: 0px 2px 5px 0px #3C425714, 0px 1px 1px 0px #0000001F;
    border-radius: 4px;
    min-width: 150px;

    .ng-arrow-wrapper {
      padding-right: 0 !important;

      .ng-arrow {
        transition: all .3s ease-in-out;
        border: unset !important;
        background: url(../../../assets/images/icons/arrow-select.svg);
        width: 10px;
        height: 6px;
      }
    }

    .ng-value-container {
      padding-left: 0;

      .ng-input {
        padding-left: 10px !important;
        padding-right: 0px !important;
      }

      .ng-placeholder {
        font-size: 15px !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        letter-spacing: -0.150000006px !important;
        color: #414552 !important;
        opacity: .7;
      }
    }
  }

  &.payments-select {
    .ng-select-container {
      min-width: 0 !important;
      width: 98%;
    }
  }
}

// tabset feature css 
nb-tabset .tab-link {
  margin-right: 30px;
}

.tabset .tab a:before {
  border-radius: 0px !important
}

// tabset feature css 



;

$table-color-label: #30313D;



// ngx table   
ngx-datatable {
  // padding: 0 30px;
}

.ngx-datatable .datatable-body-cell {
  display: flex !important;
  align-items: center;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.datatable-header-cell-template-wrap {
  text-align: center;

  .datatable-header-cell-label {
    font-family: "SF UI Text Medium";
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.34px;
    color: #1A1F36;

  }
}

.datatable-row-group {
  cursor: pointer;
  border-bottom: 2px solid #F6F8FA;
  background: transparent;
  display: flex;
  align-items: center;

  &:hover {
    // background: #2984FFB2;
    background: #f6f8fa;
    border-radius: 4px;

    // transition: background .1s ease-in;
    .table-label {
      // color: #FFFFFF;
    }

    .show-more-actions {
      border: 0.3px solid #A5A5A5CC;
    }
  }
}

.table-label {
  font-family: 'Poppins Regular';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3400000035762787px;
  text-align: center;
  color: $table-color-label;

  &.bold {
    font-weight: 600;
  }
}

.status-label {
  min-width: 75px;
  height: 20px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  font-family: 'SF UI Text Regular';
  margin: auto;

  &.status-draft {
    background: #ff996673;
    color: #ff9966;

  }

  &.status-1 {
    background: #D4E4F9;
    color: #3F91FF;
  }

  &.status-2 {
    background: #00A1C273;
    color: #006908;

  }

  &.status-3,
  &.status-4,
  &.status-5 {
    background: #E9D7FFE5;
    color: #5A66DC;

  }

  &.status-6,
  &.status-7 {
    background: #F6E6B9;
    color: #414552;
  }

  &.status-8 {
    background: #D7F7C2;
    color: #006908;
  }

  &.status-Due {
    background: #ACACAC4D;
    color: #414552;
  }

  &.status-Overdue {
    background: #FFADAD;
    color: #DF1B41;
  }

  &.status-Partial {
    background: #F6E6B9;
    color: #414552;
  }

  &.status-Paid {
    background: #D7F7C2;
    color: #006908;
  }
}

.button-group-show {
  border-radius: 8px;

  &.active {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .show-btn-expand {
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: opacity .25s;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.show-more-actions {
  // width: 47px;
  padding: 6px 13px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #FFFEFE, #FFFEFE);

  nb-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total-items {
    font-family: 'SF Pro Display';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #687385;
  }

  .pagination-container {}

}


.ngx-pagination {
  display: flex;
  align-items: center;
  margin-bottom: auto !important;
  margin-left: auto !important;
  margin: auto !important;

  .disabled {
    padding: 0 !important;
  }

  .pagination-previous a:before,
  .pagination-previous.disabled:before {
    content: '' !important;
    display: block !important;
    margin-right: 0 !important;
  }

  .pagination-next a:after,
  .pagination-next.disabled:after {
    content: '' !important;
    display: block !important;
    margin-left: 0 !important;
  }

  li {
    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }

    font-family: 'Poppins Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 15px;
    text-align: center;
    background: transparent !important;

    &.current {
      color: #6A7383 !important;
    }

    a {
      text-decoration: none;
      color: #6a738363;
    }
  }

  li.pagination-next,
  li.pagination-previous,
  li.pagination-next,
  li.pagination-previous {
    &.disabled {

      a,
      span {
        color: #6A73834D;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
          linear-gradient(0deg, rgba(60, 66, 87, 0.12), rgba(60, 66, 87, 0.12));
        border: 1px solid #3C42571F;
        box-shadow: 0px -1px 1px 0px #0000001F inset;
      }
    }

    a,
    span {
      box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.0784313725), 0px 1px 1px 0px rgba(0, 0, 0, 0.1215686275);
      border: 1px solid #3C42571F;
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(60, 66, 87, 0.12), rgba(60, 66, 87, 0.12));
      padding: 4px 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-decoration: unset;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.15399999916553497px;
      font-family: 'SF Pro Display';
      color: #687385;
    }
  }
}


.button-group-dots:hover {
  .dots-btn {
    border-color: transparent;
  }

  .show-details-action {
    opacity: 1;
    pointer-events: all;
  }
}

.dots-btn {
  height: 24px;
  z-index: 1;
  border: 1px solid rgba(60, 66, 87, 0.1215686275);
  flex: 0 auto;
  margin: 0;
  border-radius: 6px;
  text-decoration: none;
  position: relative;

  nb-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button-group-dots {
  // position: relative;
  height: 40px;
}

.group-show-details-row-table {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  position: absolute;
  right: 0;
  top: 0;
}

.show-details-action {
  position: relative;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s;
  // position: absolute;
  // top: 0;
  // right: 0;


  display: flex;
  position: absolute;
  border: 1px solid rgba(60, 66, 87, 0.1215686275);
  border-radius: 6px;
  overflow: hidden;

  .dots-btn-details {
    height: 24px;
    border-radius: 0;
    outline: none;
    border: none;
    box-shadow: none;

    &:first-child {
      border-right: 1px solid rgba(60, 66, 87, 0.1215686275);
    }

    &:last-child {
      border-left: 1px solid rgba(60, 66, 87, 0.1215686275);
    }

    nb-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}





.popover-section {
  background: white;
  min-width: 170px;

  hr {
    margin: 5px 0;
  }

  .popover-group {
    .type-name {
      padding: 0 15px;
      font-family: 'Poppins Medium';
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #2D2D2D80;
    }

    .popover-item {
      padding: 4px 10px;
      cursor: pointer;
      cursor: pointer;
      font-family: 'Poppins Medium';
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #0570DE;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.disab {
        opacity: .6;
        filter: grayscale(1)
      }

      &:hover {
        background: #F7F7F7;
      }

      nb-icon {
        width: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}





[nbInput].size-small {
  height: 30px;
}

::ng-deep {
  textarea.text-area {
    padding: 11px !important;
    background: #FEEDAF !important;
    border-radius: 8px !important;

    &.white {
      background: #FFF !important;
      border: 1px solid #FFF !important;
    }
  }
}

.text-capitalize {
  text-transform: capitalize;
}

ng-select.date-range-input {
  .ng-select-container {
    background: transparent !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    min-width: 100%;
  }
}

.md-drppicker .ranges ul li button.active {
  background-color: #0570DE !important;
  color: #fff !important;
  font-family: "SF UI Text Medium" !important;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: #0570DE !important;
  color: #fff !important;
  font-family: "SF UI Text Medium" !important;
  border-color: transparent !important;
}

.md-drppicker .btn {
  background-color: #0570DE !important;
  font-family: "SF UI Text Medium" !important;
}

.md-drppicker {
  font-family: "SF UI Text Medium" !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: unset;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: unset;
  border-bottom: 2px solid #F6F8FA;
}

.fc .fc-col-header-cell-cushion {
  font-family: 'SF UI Text Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3400000035762787px;
  text-align: left;
}

.fc-daygrid-day-events {
  // display: flex;
}

.event-item-calendar {
  cursor: pointer;
  width: 100%;
  height: 20px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "SF UI Text Regular";
  margin: 2px;

  i {
    font-style: normal;
  }
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-bottom: 10px;
}

.fc-daygrid-day-number {
  font-family: "SF UI Text Regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3400000035762787px;
  text-align: center;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;

  .fc-daygrid-day-top {
    .fc-daygrid-day-number {
      width: 26px;
      height: 26px;
      padding: 3px 4.5px;
      border-radius: 100px;
      color: #FFF;
      background: #2984FFE5;
      font-weight: 600;
    }
  }
}

.fc .fc-daygrid-more-link {
  font-family: "SF UI Text Regular";
  color: #545a6a;
  font-size: 12px;
  line-height: 15px;
  font-style: italic;
  text-decoration: unset;
}

.fc-popover {
  display: none;
}

.line {
  margin: 10px 0;
  border-bottom: 1px solid #f6f8fa;
}


// nb-badge {
//   right: 20px !important;
//   top: 10px !important;
//   position: absolute;
//   width: 12px;
//   height: 12px;
//   border-radius: 100px;
//   background: linear-gradient(0deg, #DF1B41, #DF1B41);
//   font-family: "SF UI Text Regular";
//   border: 1.5px solid #FFFFFF;
//   color: white;
//   font-size: 7px;
//   font-weight: 600;
//   letter-spacing: 0px;
//   display: flex;
//   text-align: center;
//   align-items: center;
//   justify-content: center;
//   padding: 0;
// }
.badge-danger-notify {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: linear-gradient(0deg, #DF1B41, #DF1B41);
  font-family: "SF UI Text Regular";
  border: 1.5px solid #FFFFFF;
  color: white;
  font-size: 7px;
  font-weight: 600;
  letter-spacing: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0;
}





.group-feathure-box {
  margin-top: 10px;

  .label-title {
    font-family: 'SF UI Text Medium';
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.3400000035762787px;
    text-align: left;
    color: #414552;
  }

  .label-desc {
    font-family: 'SF UI Text Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.15000000596046448px;
    text-align: left;
    color: #6A7383;
  }

  .label-action-link {
    margin-top: 5px;
    font-family: 'SF UI Text Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.15000000596046448px;
    text-align: left;
    color: #3F91FF;
  }


}

.label-action-link {
  cursor: pointer;
}

.up-in-toggle {
  display: flex;

  input {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked+label {
      font-weight: 500;
      color: #0570DE;
      border: 1px solid #0570DE;
    }
  }

  label {
    border: 1px solid #FFF;
    display: inline-block;
    width: 80px;
    color: rgb(165, 173, 183);
    padding: 6px 6px;
    transition: all 0.1s ease-in-out;
    margin-bottom: 0;
    font-family: 'SF UI Text Medium';
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;



    &:hover {
      cursor: pointer;
    }
  }
}

.up-in-toggle label:first-of-type {
  border-radius: 25px 0 0 25px;
}

.up-in-toggle label:last-of-type {
  border-radius: 0 25px 25px 0;
}

.toggle-container {
  border-radius: 25px;
  overflow: hidden;
  background: white;
  border: 1px solid #ACACAC4D;
}


.mat-slider-horizontal .mat-slider-wrapper {
  background: #e9ecef;
}

.mat-accent .mat-slider-track-fill,
mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-thumb {
  background: #0670de;
  background-color: #0670de;
}

::ng-deep {
  .iti {
    width: 100%;
  }

  .form-control-custom {
    width: 100%;
    background-color: #F7F7F7;
    border: 1px solid #F7F7F7;
    color: #222b45;
    height: 50px;
    border-radius: 7px;
  }
}

.amountLabel,
.beforeLabel {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc((100% / 2) - 4px);
    width: 9px;
    height: 9px;
    border-radius: 100px;
    background: #0670de;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: calc((100% / 2) - 4px);
    width: 9px;
    height: 9px;
    background: #bdbdbd;
    border-radius: 100px;
  }
}

.tooth_name {
  font-size: 20px;
  color: #FFF;
}

.mat-slider-horizontal .mat-slider-thumb-label-text {
  color: #FFF;
}

.non-label {
  label {
    padding-right: 0 !important;
  }
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  overflow: hidden !important;
  white-space: initial !important;
  text-overflow: ellipsis !important;
}


.iti {
  width: 100% !important;
}

.phone-country-input {
  font-family: "SF UI Text Regular";
  height: 30px !important;
  line-height: 20px !important;
  font-size: 12px !important;
  letter-spacing: 0.15px !important;
  color: #414552 !important;
  padding: 4px 0px 4px 45px !important;
  min-height: 30px !important;
  border: 1px solid #D5DBE1;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D5DBE1, #D5DBE1);
  box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.0784313725), 0px 1px 1px 0px rgba(0, 0, 0, 0.1215686275);
  border-radius: 4px;
  min-width: 150px;
  width: 100%;

  :focus-visible {
    outline: 4px dashed black;
  }

}

.dropdown-menu.country-dropdown.show {
  position: fixed !important;
  top: calc(100% - 60%) !important;
  left: 35% !important;
  width: 30% !important;
  height: 30% !important;
  overflow: auto;

  &::before {
    content: '';
  }

  .iti__country-list {
    height: 100% !important;
    max-height: 100% !important;
    overflow-x: hidden !important;

    &::-webkit-scrollbar-track {
      background: red;
    }

    &::-webkit-scrollbar-thumb {
      background: red;
      cursor: pointer;
      border-radius: 10px
    }

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-corner {
      background: red
    }

  }

}

.iti .search-container {

  input,
  input[type=text],
  input[type=tel] {
    font-family: "SF UI Text Regular";
    height: 30px !important;
    line-height: 20px !important;
    font-size: 12px !important;
    letter-spacing: 0.15px !important;
    color: #414552 !important;
    padding: 4px 0px 4px 10px !important;
    min-height: 30px !important;
    border: 1px solid #D5DBE1;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D5DBE1, #D5DBE1);
    box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.0784313725), 0px 1px 1px 0px rgba(0, 0, 0, 0.1215686275);
    border-radius: 4px;
    min-width: 150px;
    width: 100%;

    &:focus-visible {
      outline: unset;
    }
  }
}

.iti__flag-box,
.iti__country-name {
  font-family: 'SF UI Text Medium' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
  text-align: left !important;
}

.result-audio {
  height: 25px;
}

.file-box {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;

  .uploaded-file {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .finish-upload-icon {
    z-index: 9;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.finish-upload-icon:hover~.done-upload {
  opacity: .6;
  filter: grayscale(1)
}

.start-button,
.stop-button,
.cancel-button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin: 0 5px;

  img {
    width: 100%;
  }

  opacity: .5;
  transition: all .3s ease-in-out;

  &:hover {
    opacity: 1;
    transition: all .3s ease-in-out;
  }
}

nb-card-header,
nb-card-body,
nb-card-footer {
  padding: 0 !important;
}


.back-navigation {
  font-family: 'SF UI Text Medium';
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  text-align: left;
  color: #6A7383;
  padding: 2px 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}


input:disabled {
  background: #dddddd;
}

[nbInput].disabled {
  background-color: #f7f9fc;
  border-color: #e4e9f2;
  color: #8f9bb37a;
  pointer-events: none;
}

@media print {
  // .bar-code {
  //   margin-top: 7px;
  //   .barcode svg {
  //     height: 43vh;
  //   }
  // }


}

.accordion-faq-link {
  box-shadow: none !important;

  nb-accordion-item {
    margin-bottom: 20px
  }

  nb-accordion-item-header {
    border: none !important;
    margin-bottom: 7px;
  }

  nb-accordion-item-body {
    .item-body {
      padding: 0;
    }
  }
}

nb-toggle {
  label {
    margin-bottom: 0 !important;
  }
}

nb-toggle.reverse.status-basic .native-input:enabled+.toggle:hover.checked {
  background-color: #8f9bb314 !important;
  border-color: #8f9bb3 !important;
}

nb-toggle.reverse.status-basic .toggle.checked {
  background-color: #8f9bb314 !important;
  border-color: #8f9bb3 !important;
}

nb-toggle.reverse.status-basic .toggle {
  background-color: #598bff !important;
  border-color: #598bff !important;
}

.large-input {
  border: 1px solid #ACACACB2 !important;
  position: relative !important;
  font-family: 'SF UI Text Medium' !important;
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  letter-spacing: 0.34000 00035762787px !important;
  text-align: left !important;
  color: #30313D !important;
}

.input-icon {
  position: absolute;
  z-index: 9;
  right: 9px;
  top: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checker-pagination {
  .ngx-pagination li {
    &.pagination-next {
      &.disabled {

        a,
        span {
          &::after {
            opacity: .4;
          }
        }
      }

      a,
      span {
        box-shadow: 0px -1px 1px 0px #0000001F inset, 0px 2px 5px 0px #3C42571F, 0px 1px 1px 0px #00000014;
        background: #0570DE;
        width: 58px;
        height: 48px;
        border-radius: 0 4px 4px 0;

        &::after {
          content: url('../../../assets/images/icons/prev-up-arrow.svg') !important;
          position: absolute;
        }
      }
    }

    &.pagination-previous {
      &.disabled {

        a,
        span {
          &::before {
            opacity: .4;
          }
        }
      }

      a,
      span {
        box-shadow: 0px -1px 1px 0px #0000001F inset, 0px 2px 5px 0px #3C42571F, 0px 1px 1px 0px #00000014;
        background: #0570DE;
        width: 58px;
        height: 48px;
        border-radius: 4px 0 0 4px;

        &::before {
          content: url('../../../assets/images/icons/next-up-arrow.svg') !important;

          position: absolute;
        }
      }
    }

    &:first-child {
      margin-right: 0px;
    }

    &:last-child {
      margin-left: 0px;
    }
  }
}

.left-accordion-arrow .expansion-indicator {
  pointer-events: all;
  left: 1rem;
}

.left-to-left-accordion-arrow .expansion-indicator {
  pointer-events: all;
  left: 0;
  transform: rotate(270deg);
}

.select-label-text {
  font-family: 'SF UI Text Regular';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #30313D;
  letter-spacing: 0.34px;
}

.order-content-print.finish-order-modal {
  .order-content-details {
    border: 1px solid #d5dbe1;
    padding: 15px;
    border-radius: 6px;

    &::before {
      position: absolute;
      width: 125px;
      height: 125px;
      left: -125px;
      top: 0;
      content: '';
      background-size: cover !important;
      background: url('../../../assets/images/conffeti-md.png') no-repeat 0 center;
    }

    &::after {
      position: absolute;
      width: 130px;
      height: 130px;
      right: -135px;
      top: 21px;
      content: '';
      transform: scaleX(-1);
      background-size: cover !important;
      background: url('../../../assets/images/conffeti-md.png') no-repeat 0 center;
    }
  }

}

// * {
//   border: 1px solid red;
// }
.empty-order {
  display: flex;

  .empty-order-context {
    .title {
      font-family: 'SF UI Text Medium';
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.3400000035762787px;
      text-align: left;
      color: #414552;
    }

    .desc {
      margin-top: 8px;
      font-family: 'SF UI Text Medium';
      font-size: 16px;
      font-style: italic;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.3400000035762787px;
      text-align: left;
      color: #6A7383B2;

    }
  }

  .empty-order-image {
    margin-left: 10px;
  }
}

ng-select.disabled-select .ng-select-container,
[nbInput].disabled-select {
  opacity: 1 !important;
  background: #eeeeee94 !important;
  color: #6f7073 !important;
  pointer-events: none;
}

.point-event {
  pointer-events: all;
}

[nbButton].appearance-filled.status-basic[disabled] {
  opacity: 25%;
  box-shadow: 0px 2px 5px 0px #3C42571F, 0px 1px 1px 0px #00000014;

}


.tutorial-action {
  cursor: pointer;
  display: flex;
  //gap: 10px;
  align-items: center;

  .tutorial-icon {
    width: 20px;
    margin: 0 10px;
  }

  .tutorial-label {
    font-family: 'SF UI Text Medium';
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #0570DE;
  }
}


.lower {
  .image-container {
    .color-teeth {
      transform: rotate(180deg);
    }
  }
}

.show-password .nb-form-field-suffix-medium {
  margin-left: calc(40px * -1);
}

.ng-value-label .fake-radio {
  display: none;
}

.custom-select-radio {
  .ng-option {
    .fake-radio {
      width: 16px;
      height: 16px;
      border-radius: 100px;
      border: 1px solid #A5ABB2;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: none;
      }
    }

    &.ng-option-selected {
      .fake-radio {
        img {
          display: block;
        }
      }
    }
  }
}


@media print {
  .isPrinting>* {
    display: none;
  }

  .isPrinting ngx-print-template {
    display: block;
  }
}


.export-button {
  angular2csv {
    button {
      display: none;
    }
  }
}



@media print {
  .datatable-row-center {
    display: flex !important;
  }

  .calendar-order-modal {
    .datatable-header {
      width: 100% !important;

      .datatable-row-center {
        display: flex !important;
      }
    }

    .ngx-datatable.scroll-horz .datatable-body {
      width: 100% !important;
      overflow: hidden !important;
    }

    .datatable-body-cell {
      white-space: normal !important;
      word-wrap: break-word !important;
      overflow-wrap: break-word !important;
    }

    .filter-options {
      display: none !important;
    }

    .filter-items-calendar {
      display: none !important;
    }

    nb-card-footer {
      display: none !important;
    }

    ngx-datatable {
      pagination-controls {
        display: none !important;
      }

      ngx-datatable-footer {
        display: none !important;
      }
    }

  }
}

.md-drppicker {
  .buttons_input {
    .btn {
      &.clear svg {
        display: none;
      }

      border-radius: 6px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      text-transform: capitalize;
      font-family: 'SF UI Text Medium' !important;
      background: #0570DE !important;
      box-shadow: 0px -1px 1px 0px #0000001F inset,
      0px 2px 5px 0px #3C42571F,
      0px 1px 1px 0px #00000014 !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      letter-spacing: -0.15000000596046448px !important;
      text-align: center !important;
      color: #fff !important;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
}

.item-title-select {
  font-family: 'SF UI Text Medium';
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.34px;
  color: #1A1F36;
  margin-left: 5px;
}




.phone-number-input {
  height: 30px;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 0.1875rem 1rem 0.1875rem 2.9rem;
  background-color: #FFFFFF;
  border-color: #E0E6EB;
  color: #6A7383;
  border-style: solid;
  border-width: 1px;
  font-family: 'SF UI Text Regular';
  appearance: none;
}




.settings-size-container {
  .size-config-settings {
    .size-config-select .ng-select-container {
      width: 70px !important;

      min-width: unset;
    }
  }
}



.ng-select.size-config-select .ng-select-container {
  width: 70px !important;
  font-family: "SF UI Text Regular";
  height: 30px !important;
  line-height: 20px !important;
  font-size: 12px !important;
  letter-spacing: 0.15px !important;
  color: #414552 !important;
  padding: 0.1875rem 0.1875rem 0.1875rem 1rem !important;
  min-height: 30px !important;
  border: 1px solid #D5DBE1;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D5DBE1, #D5DBE1);
  box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.0784313725), 0px 1px 1px 0px rgba(0, 0, 0, 0.1215686275);
  border-radius: 4px;
  min-width: unset
}

.selected-row {
  background-color: #f6f8fa;
}

.custom-column-table {
  .datatable-body-cell-label {
    width: 100%;
  }
}

.settings-bt-clinet {
  border: 1px solid #3F91FF !important;
  color: #3F91FF !important;
  min-width: 105px !important;
  border-radius: 6px !important;
  box-shadow: 0px 2px 5px 0px #3C42571F, 0px 1px 1px 0px #00000014 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: -0.15000000596046448px !important;
  font-family: 'SF UI Text Medium' !important;
}

.great-button-danger {
  background-color: #F07C7C !important;
  border-color: #F07C7C !important;
  font-family: 'SF UI Text Regular' !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.15000000596046448px !important;
  text-align: center !important;
  box-shadow: 0px -1px 1px 0px #0000001F inset, 0px 2px 5px 0px #3C42571F, 0px 1px 1px 0px #00000014 !important;
  color: #FFFFFF !important;
  border-radius: 6px !important;
  height: 32px !important;
}

.edit-icon-box {
  nb-icon {
    width: 17px;
    height: 17px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.settings-btn {
  border: 1px solid #3F91FF !important;
  color: #3F91FF !important;
}

.breadcrumbs-item {
  cursor: pointer;
}

.client-list,
.permissions-list {
  label {
    padding: 2.5px 0 !important;
  }
}



.create-form-body {
  max-height: 80vh;
}

.ngx-datatable {
  overflow: unset !important;

  datatable-header {
    overflow: unset !important;
    position: sticky;
    background: #fff;
    top: -10px;
    z-index: 100;

    &:before {
      content: ' ';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background: #fff;
    }
  }
}

.ngx-datatable.material.single-selection .datatable-body-row.active {
  background-color: #f2f2f3;
}


.filters-container {
  .setting-table-columns {
    ng-select {
      .ng-select-container {
        height: 30px !important;
        box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.0784313725), 0px 1px 1px 0px rgba(0, 0, 0, 0.1215686275) !important;
      }
    }
  }
}

.link-href {
  color: unset;
  cursor: pointer;

  &:hover {
    color: #0570de;
  }
}

.navigation-tabs .tabset .tab a {
  padding-top: 0 !important;
}

.w-33 {
  flex: 0 0 33.3333333333% !important;
  width: 33.3333333333% !important;
}













































datatable-body {
  &::-webkit-scrollbar-track {
    background: red;
  }

  &::-webkit-scrollbar-thumb {
    background: red;
    cursor: pointer;
    border-radius: 10px
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-corner {
    background: red
  }
}

.textarea-container {
  background: #feedaf;
  border: 1px solid #F6E6B9;
  border-radius: 8px;
  padding: 8px 12px;

  &.reply {
    background: #FFF;
    border: 1px solid #ACACAC4D;
    box-shadow: 1px 3px 15px 0px #5C5C5C14, 0px 2px 3px 0px #00000005;

    textarea.text-area {
      padding: 11px !important;
      background: #FFF !important;
      border: 1px solid #FFF !important;

    }
  }
}


.teeth-legend-hover {
  padding: 10px;

  .thead {
    padding: 3px 8px;
    font-family: "SF UI Text Medium";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #6A7383;
  }

  .tbody {
    text-transform: capitalize;
    padding: 3px 8px;

    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.3400000036px;
    text-align: left;
    color: #30313D;
  }
}


nb-popover.nb-overlay-top .arrow {
  display: none !important;
}

stl-model-viewer {
  width: 100%;
  height: 100%;
  display: block;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

nb-tooltip {
  margin-top: -10px;

  .arrow {
    border-left: unset !important;
    border-right: unset !important;
  }
}



.receiving-footer-select {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.receiving-header-select {
  .receiving-header-title {
    font-family: "SF UI Text Regular";
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    text-align: left;
    color: #2D2D2D80;
  }

  .receiving-header-types {
    display: flex;
    flex-direction: column;

    .receiving-type-item {
      cursor: pointer;
      padding: 4px;
      border-radius: 4px;
      font-family: "SF UI Text Regular";
      font-size: 14px;
      font-weight: 500;
      line-height: 16.71px;
      text-align: left;
      color: #30313D;
      transition: all .1s ease-in;

      &.select,
      &:hover {
        background: #D4E4F9;
      }
    }
  }

}

.ng-dropdown-panel .ng-dropdown-footer {
  border-color: transparent !important;
  padding: 8px !important;
}

.ng-dropdown-panel .ng-dropdown-header {
  border-color: transparent !important;
  padding: 0 !important;

  .receiving-header-select {
    box-shadow: 0px 0.7px 1px 0px rgba(92, 92, 92, 0.0901960784), 1px 2px 3px 0px rgba(0, 0, 0, 0.1019607843) !important;
    padding: 8px 8px 3px 8px !important;
    margin-bottom: 5px;
  }
}

.received-select-label-text {
  font-family: "SF UI Text Medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.71px;
  text-align: left;
  color: #30313D;
  text-wrap: wrap;
}

.receiving-select-dropdown {
  width: 238px !important;

  .radio-option {
    width: 16px;
    height: 15px;
    border-radius: 100px;
    background: #FFFFFF;
    border: 1px solid #A5ABB2;
  }

}

.receiving-select-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  .radio-option {
    display: flex;
    align-items: center;
    justify-content: center;

    .selected-radio {
      width: 10px;
      height: 10px;
      background: #0570DE;
      border-radius: 100px;
      margin: auto;
    }
  }
}

.receiving-option-item {}

.absolute-select-receiving {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  .custom-receiving-select {
    width: 40px !important;

    .ng-select-container {
      min-width: 40px !important;
      min-height: 21px !important;
      height: 21px !important;
      width: 40px !important;
      padding: 1px !important;
      display: flex;
      align-items: center;

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex: unset !important;

        .ng-input {
          top: unset !important;
        }

        .ng-value {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .ng-value-label {
            font-size: 13px;
          }
        }
      }
    }


  }
}

.analog-label-select {
  margin-left: 50px;
}

ng-dropdown-panel.custom-receiving-select {
  z-index: 999999999 !important;

  .ng-dropdown-panel-items {
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      cursor: pointer;
      border-radius: 10px
    }

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-corner {
      background: transparent
    }
  }
}

.receiving-text-input {
  border: 1px solid #FEEDAF;
  background: #FEEDAF;
  height: 32px;
  border-radius: 2px;
  padding: 7px 11px 8px 11px;
  font-family: "SF UI Text Regular";
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 16.71px;
  text-align: left;
  color: #414552;
}

.disabled-section {
  cursor: no-drop;
  pointer-events: none;
  opacity: .6;
  filter: grayscale(.3)
}


.add-ons-table .ngx-datatable .datatable-footer {
  position: sticky;
  bottom: -10px;
  z-index: 10;
  background: transparent;

  /* Ensure the footer has a background color */
  .selected-count {
    // display: none;
  }
}

.expand-product-footer-table {
  background: white;
  padding: 8px 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  font-family: "SF UI Text Regular";
  font-size: 13px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: -0.15000000596046448px;
  text-align: center;
  color: #1A1B25;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}

.stage-select .ng-select-container .ng-value-container {
  padding: 0 !important;

  .ng-placeholder {
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: -0.150000006px !important;
  }
}





.stages-container {
  .stages-header {
    padding: 0;
    padding-left: 30px;

    .stages-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

}

.order-notes nb-accordion-item-body .item-body {
  padding: 5px 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: nowrap !important;
}

@media print {
  pre {
      border: none;
  }
}



.cdk-overlay-container {
  z-index: 1050 !important;

}